'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.identityFunc = identityFunc;

/**
 * Returns the first argument it receives.
 */
function identityFunc(x) {
  return x;
}
