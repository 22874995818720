'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.mapValue = mapValue;

/**
 * Creates an object map with the same keys as `map` and values generated by
 * running each value of `map` thru `fn`.
 */
function mapValue(map, fn) {
  const result = Object.create(null);

  for (const key of Object.keys(map)) {
    result[key] = fn(map[key], key);
  }

  return result;
}
